jQuery(document).ready(function() {
  jQuery(document).on("nfFormReady", function() {
    // Append arrow to Home Subscription Form
    jQuery(
      '<a href="javascript:;" data-trigger="user-subscribe"><i class="fa fa-angle-right"></i></a>'
    ).insertAfter(
      "body.home .subscribe-wrapper .nf-form-cont .nf-form-layout .nf-form-content .email-container .email-wrap input"
    );

    // Click on above arrow to submit form
    jQuery(
      "body.home .subscribe-wrapper .nf-form-cont .nf-form-layout .nf-form-content .email-container .email-wrap"
    ).on("click", "[data-trigger=vote_comp]", function() {
    
    });

    // Set eqal height for map on Contact page
    if (jQuery(".get-in-touch-wrapper").get().length > 0) {
      jQuery(".get-in-touch-wrapper")
        .find(".map-wrapper .uncode-map-wrapper")
        .css(
          "height",
          jQuery(".get-in-touch-wrapper")
            .find(".details-and-form-wrapper .uncell:first-of-type")
            .outerHeight() + "px"
        );
    }

    // Fix Ninja Forms submit on enter key press (all forms)
    jQuery(".nf-form-cont input:not([type=button])").keypress(function(e) {
      if (e.keyCode == 13) {
        jQuery(".nf-form-cont .submit-container input[type=button]")
          .focus()
          .trigger("click");
      }
    });
  });

  // Remove empty tagline elements from pages
  if (jQuery(".generic-header-wrapper").get().length > 0) {
    if (
      jQuery.trim(
        jQuery(".generic-header-wrapper")
          .find(".custom-page-tagline > .heading-text")
          .html()
      ) == ""
    ) {
      jQuery(".generic-header-wrapper")
        .find(".row-internal")
        .css("display", "none");
      jQuery(".generic-header-wrapper")
        .find(".custom-page-title hr")
        .css("display", "none");
    }
  }

  // Remove category links and parent category from Press Coverage
  if (jQuery(".press-coverages-wrapper").get().length > 0) {
    jQuery(".isotope-system").on("arrangeComplete", function() {
      jQuery(
        ".press-coverages-wrapper .t-entry-category a:contains('Press Coverage')"
      )
        .parent()
        .css("display", "none");
      jQuery(".press-coverages-wrapper .t-entry-category a").attr(
        "href",
        "javascript:;"
      );
    });
  }

  if (jQuery(".isotope-title-to-new-tab").get().length > 0) {
    jQuery(".isotope-system").on("arrangeComplete", function() {
      jQuery(".isotope-title-to-new-tab")
        .find(
          ".isotope-wrapper > .isotope-container > .tmb > .t-inside > .t-entry-text .t-entry-title > a"
        )
        .attr("target", "_blank");
    });
  }
});
